import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import { white } from '@/style-guide/colors'

import Layout, { TopBar, Featured, Footer } from '@/components/Layout'

import SEO from '@/components/seo'

import Header from './Header'
import SubHeader from './SubHeader'
import SuccessStories from './SuccessStories'

const Container = styled.div`
  background-color: ${white};
  height: 100vh;
  width: 100%;
`

const Content = styled.div`
  padding-bottom: 40px;

  ${media.greaterThan('medium')`
    margin-top: 60px;
  `}
`

const AboutUs = () => (
  <>
    <SEO title="About Us" />
    <Layout>
      <Container>
        <TopBar />
        <Header />
        <Content>
          <SubHeader />
          <SuccessStories />
          <Featured />
        </Content>
        <Footer />
      </Container>
    </Layout>
  </>
)

export default AboutUs
