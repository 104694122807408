import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import ceo from '@/assets/people-images/ceo.png'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  padding: 0px 32px;

  ${media.greaterThan('medium')`
    padding: 0px
    margin-top: 0px;
  `}
`

const Description = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: #7f92a3;

  ${media.greaterThan('medium')`
    text-align: center;
    width: 728px;
  `}
`

const SubTitle = styled.span`
  font-weight: bold;
  font-size: 14px;
  margin-top: 60px;
  line-height: 24px;

  text-transform: capitalize;

  color: #4472de;

  ${media.greaterThan('medium')`
    line-height: 50px;
    text-align: center;
  `}
`

const Title = styled.span`
  font-weight: bold;
  font-size: 42px;
  line-height: 50px;

  text-align: center;
  text-transform: capitalize;

  color: #092c4c;
  margin-top: 20px;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${media.greaterThan('medium')`
    width: 670px;
    flex-direction: row;
  `}
`
const Img = styled.img`
  width: 259px;
  height: 343px;
  align-items: center;
  margin-top: 25px;

  ${media.greaterThan('medium')` 
    margin: 0px 65px 0px 0px;
    width: unset;
    height: unset;
  `}
`

const Text = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: #7f92a3;
`

const ContentTitle = styled(Text)`
  margin-top: 55px;
`

const ContentDescription = styled(Text)`
  margin-top: 45px;
`

const Author = styled(Text)`
  color: #4472de;
  font-weight: bold;
  margin-top: 45px;
`

const Aside = styled.div`
  display: flex;
  flex-direction: column;
`

const SubHeader = () => (
  <Container>
    <Description>
      Far too often, we perceive the world through the lens of our emotions, feelings, and
      intuition. Meanwhile, a multitude of our problems can be solved with a tiny bit of math. Buy
      or rent a house? How long to boil an egg? Am I overweight? What it takes to answer these
      questions is a clear mind, a few minutes, and a mathematical formula. Three things that we
      often lack, so we go with hunches instead.
    </Description>
    <SubTitle>Instead of calculating one thing, we’ll calculate all of them.</SubTitle>
    <Title>About Us</Title>
    <Content>
      <Img src={ceo} alt="CEO" />
      <Aside>
        <ContentTitle>Lorem ipsum dolor sit amet, consectetur.</ContentTitle>
        <ContentDescription>
          adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
          enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur.
        </ContentDescription>
        <Author>Tony Vu</Author>
        <Text>Founder &amp; CEO</Text>
      </Aside>
    </Content>
  </Container>
)

export default SubHeader
