import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import teacher from '@/assets/people-images/teacher.jpg'

import { white } from '@/style-guide/colors'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  ${media.greaterThan('medium')`
  margin-top: 120px;
    flex-direction: row;
  `}
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 629px;
  width: 60%;
  background: #4472de;

  ${media.greaterThan('medium')`
    width: 508px;
    flex-direction: row;
  `}
`

const Aside = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #f6f6f6;
  padding: 45px;

  ${media.greaterThan('medium')`
    width: 793px;
    height: 601px;
    padding: 0px 120px;
  `}
`

const RelativeCol = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  left: 75px;

  ${media.greaterThan('medium')`
    left: 175px;
  `}
`

const Text = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: #7f92a3;
`

const Title = styled(Text)`
  font-weight: bold;
  color: ${white};
`

const Img = styled.img`
  width: 297px;
  height: 381px;
  margin-top: 20px;

  box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.25);
`

const Description = styled(Text)`
  color: #092c4c;
  font-size: 16px;
  line-height: 26px;

  ${media.greaterThan('medium')`
    font-size: 22px;
    margin-top: 45px;
    line-height: 38px;
  `}
`

const Author = styled(Text)`
  font-weight: bold;
  font-size: 16px;
  line-height: 38px;
  color: #092c4c;
  margin-top: 45px;
`

const SuccessStories = () => (
  <Container>
    <Content>
      <RelativeCol>
        <Title> &mdash; Success Story</Title>
        <Img src={teacher} alt="CEO" />
      </RelativeCol>
    </Content>
    <Aside>
      <Description>
        You would think a &quot;calculator website&quot; would be limited in scope to the
        &quot;math&quot; stuff. You could not be more wrong. They have a big range - from finances,
        to health, construction and everyday. Basically if something can be calculated they do it.
      </Description>
      <Author>Teacher</Author>
      <Text>California High School</Text>
    </Aside>
  </Container>
)

export default SuccessStories
